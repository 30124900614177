import React from "react";
import { Outlet } from "react-router-dom";
import MediaCenterNav from "../components/navbars/mediaCenterNav";

/* Styled components */

const HorseMediaCenterPage = () => {

    return (
        <MediaCenterNav>
            <Outlet />
        </MediaCenterNav>
    );
}

export default HorseMediaCenterPage;