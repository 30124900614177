import React from "react";
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import translate from "../providers/i18n/translate";
import { useParams } from "react-router-dom";
import Media from "../components/mediacenter/Media";
import AddIcon from '@mui/icons-material/Add';
import { Modal, Tooltip } from "@mui/material";
import NewMedia from "../components/mediacenter/NewMedia";
import { API_URL } from "../config";
import axios from 'axios';

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
    border: solid ${props => props.theme.palette.principal};
    border-top: none;
    @media (min-width: 1024px) {
        flex-direction: row;
    }
`

const ContentMedia = styled.div`
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px;
    @media (min-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: auto;
        gap: 20px;
    }
    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: auto;
        gap: 20px;
    }
`

const NoData = styled.h2`
    color: ${props => props.theme.palette.principal};
`

const NewIcon = styled.div`
    position: fixed;
    right: 20px;
    bottom: 150px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${props => props.theme.palette.accent};
    color: ${props => props.theme.palette.base};
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    &:hover {
        transform: scale(1.1);
    }
    z-index: 99;
    @media (min-width: 1024px) {
        right: 50px;
        bottom: 50px;
    }
`

const EducationPage = () => {

    const { id } = useParams();

    const intl = useIntl();

    const [ medias, setMedias ] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        getMedias();
        setOpen(false);
    }

    const handleNewMedia = () => {
        handleOpen();
    }

    const handleDelete = (idMedia) => {
        axios.delete(`${API_URL}/mediacenter/${idMedia}`)
        .then(result => {
            getMedias();
        })
        .catch(err => {
            console.log(err.response.data.msg);
        })
    }

    const getMedias = () => {
        axios.get(`${API_URL}/mediacenter/educations/${id}`)
        .then(result => {
            setMedias(result.data.rows)
        })
        .catch(err => {
            console.log(err.response.data.msg);
        })
    }

    React.useEffect(() => {
        if(!medias){
            getMedias();
        }
    }, [medias])

    return (
        <Root>
            {
                medias && medias.length > 0 ?
                <ContentMedia>
                    {
                        medias.map((m, index) => 
                            <Media key={`media_${index}`} data={m} handleDelete={handleDelete} />
                        )
                    }
                </ContentMedia>
                :
                <NoData>{ translate("no data") }</NoData>
            }
            <Tooltip title={intl.formatMessage({ id: 'new media' })}>
                <NewIcon onClick={handleNewMedia}><AddIcon /></NewIcon>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <NewMedia id={id} url="mediacenter/educations" handleClose={handleClose} />
            </Modal>
        </Root>
    );
}

export default EducationPage;

const dataExample = []